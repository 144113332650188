@import styles/_variables

.wrapper
  align-items: center
  background-color: #fff
  border-bottom: 1px solid #e6e6e6
  color: #666
  display: flex
  flex-direction: row
  font-size: 1.1em
  justify-content: space-between
  overflow: hidden
  padding: 1em .5em

  &:hover
    background-color: #f9f9f9

.wrapperLast
  border-bottom: none

.icon
  align-items: center
  display: flex
  font-size: 0.8em
  flex-grow: 0
  flex-shrink: 0
  justify-content: flex-start

  & svg
    color: $color-primary
    transform: rotateZ(90deg)
    transition: transform .25s ease, color .25s ease

.wrapperCollapsed
  .icon
    & svg
      color: #666
      transform: rotateZ(0deg)
