@import styles/_variables

.button,
button.button,
a.button
  background-color: $button-default-bg
  border: 1px solid $button-default-bg
  border-radius: 999px
  color: $button-default-color
  display: inline-block
  font-family: $font-primary
  font-size: 1rem
  font-weight: 400
  inset: 0
  letter-spacing: .02rem
  margin: .5em 0
  min-width: 9rem
  outline: 0
  overflow: hidden
  padding: 0
  position: relative
  text-align: center
  text-decoration: none
  width: auto

  &:hover,
  &:active
    background-color: $button-default-bg-hover
    border-color: $button-default-bg-hover
    color: $button-default-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: $button-default-bg-disabled
    border-color: $button-default-bg-disabled
    color: $button-default-color-disabled
    cursor: default
    text-shadow: none

.buttonInside,
button.buttonInside,
a.buttonInside
  align-items: center
  display: flex
  flex-direction: row
  justify-content: center
  padding: .5rem 1rem

.buttonBlock,
button.buttonBlock,
a.buttonBlock
  display: block
  width: 100%

.buttonSmall,
button.buttonSmall,
a.buttonSmall
  font-size: .875rem
  min-width: 7rem

  .buttonInside
    padding: .3rem .9rem

.buttonLarge,
button.buttonLarge,
a.buttonLarge
  font-size: 1.15rem
  min-width: 11rem

  .buttonInside
    padding: .7rem 1.1rem

.buttonPrimary,
button.buttonPrimary,
a.buttonPrimary
  background-color: $button-primary-bg
  border-color: $button-primary-bg
  color: $button-primary-color

  &:hover,
  &:active
    background-color: $button-primary-bg-hover
    border-color: $button-primary-bg-hover
    color: $button-primary-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: $button-primary-bg-disabled
    border-color: $button-primary-bg-disabled
    color: $button-primary-color-disabled
    filter: saturate(75%)
    opacity: .5

.buttonSecondary,
button.buttonSecondary,
a.buttonSecondary
  background-color: $button-secondary-bg
  border-color: $button-secondary-bg
  color: $button-secondary-color

  &:hover,
  &:active
    background-color: $button-secondary-bg-hover
    border-color: $button-secondary-bg-hover
    color: $button-secondary-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: $button-secondary-bg-disabled
    border-color: $button-secondary-bg-disabled
    color: $button-secondary-color-disabled
    filter: saturate(75%)

.buttonInfo,
button.buttonInfo,
a.buttonInfo
  background-color: $button-info-bg
  border-color: $button-info-bg
  color: $button-info-color

  &:hover,
  &:active
    background-color: $button-info-bg-hover
    border-color: $button-info-bg-hover
    color: $button-info-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: $button-info-bg-disabled
    border-color: $button-info-bg-disabled
    color: $button-info-color-disabled
    filter: saturate(75%)

.buttonDanger,
button.buttonDanger,
a.buttonDanger
  background-color: $button-danger-bg
  border-color: $button-danger-bg
  color: $button-danger-color

  &:hover,
  &:active
    background-color: $button-danger-bg-hover
    border-color: $button-danger-bg-hover
    color: $button-danger-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    background-color: $button-danger-bg-disabled
    border-color: $button-danger-bg-disabled
    color: $button-danger-color-disabled
    filter: saturate(75%)

.buttonOutline,
.buttonOutlinePrimary,
.buttonOutlineSecondary,
.buttonOutlineInfo,
button.buttonOutline,
button.buttonOutlinePrimary,
button.buttonOutlineSecondary,
button.buttonOutlineInfo,
a.buttonOutline,
a.buttonOutlinePrimary,
a.buttonOutlineSecondary,
a.buttonOutlineInfo
  background-color: transparent

.buttonOutline,
button.buttonOutline,
a.buttonOutline
  border-color: $button-outline-border
  color: $button-outline-color

  &:hover,
  &:active
    background-color: rgba($button-outline-border, .05)
    border-color: $button-outline-border-hover
    color: $button-outline-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    border-color: $button-outline-border-disabled
    color: $button-outline-color-disabled
    filter: saturate(75%)

.buttonOutlinePrimary,
button.buttonOutlinePrimary,
a.buttonOutlinePrimary
  border-color: $button-outline-primary-border
  color: $button-outline-primary-color

  &:hover,
  &:active
    background-color: rgba($button-outline-primary-border, .05)
    border-color: $button-outline-primary-border-hover
    color: $button-outline-primary-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    border-color: $button-outline-primary-border-disabled
    color: $button-outline-primary-color-disabled
    filter: saturate(75%)

.buttonOutlineSecondary,
button.buttonOutlineSecondary,
a.buttonOutlineSecondary
  border-color: $button-outline-secondary-border
  color: $button-outline-secondary-color

  &:hover,
  &:active
    background-color: rgba($button-outline-secondary-border, .05)
    border-color: $button-outline-secondary-border-hover
    color: $button-outline-secondary-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    border-color: $button-outline-secondary-border-disabled
    color: $button-outline-secondary-color-disabled
    filter: saturate(75%)

.buttonOutlineInfo,
button.buttonOutlineInfo,
a.buttonOutlineInfo
  border-color: $button-outline-info-border
  color: $button-outline-info-color

  &:hover,
  &:active
    background-color: rgba($button-outline-info-border, .05)
    border-color: $button-outline-info-border-hover
    color: $button-outline-info-color-hover

  &.buttonDisabled,
  &.buttonDisabled:hover
    border-color: $button-outline-info-border-disabled
    color: $button-outline-info-color-disabled
    filter: saturate(75%)

.buttonLink,
button.buttonLink,
a.buttonLink
  border: 0
  background: transparent
  color: $color-primary

  &:hover,
  &:active
    border: 0
    background: transparent
    color: $color-primary
    text-decoration: underline

  &.buttonDisabled,
  &.buttonDisabled:hover
    border: 0
    background: transparent
    color: lighten($color-primary, 5%)

