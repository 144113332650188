.item
  display: block

.itemToggle
  display: none

.itemLabel
  display: block
  position: relative

  &:hover
    cursor: pointer

.itemContent
  overflow: hidden
  transition: all 0.3s ease-out
