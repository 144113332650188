.divider
  border-top: 1px solid rgba(#000, 0.075)
  box-sizing: border-box
  clear: both
  color: rgba(#000, 0.85)
  display: flex
  font-feature-settings: 'tnum'
  font-size: 14px
  font-variant: tabular-nums
  line-height: 1.5715
  list-style: none
  margin: 24px 0
  min-width: 100%
  padding: 0
  width: 100%
